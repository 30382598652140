import { CoachAccreditationStatus } from 'src/components/filter-bar/preset-filters/coach-accreditation-status-select';
import { ClassicQueryFilter, VenueContactStatus, VenueRoleSubCategory } from 'src/types/classic';
import { EQUALITY_OPERATOR, LOGICAL_OPERATOR } from 'src/utils/constants/classic';

import { FiltersConfig } from './types';

const accreditationsFiltersMap = {
  [CoachAccreditationStatus.VALID]: 'ContactAccreditations.IsValid',
  [CoachAccreditationStatus.EXPIRED]: 'ContactAccreditations.IsExpired',
};

export const makeAccreditationsFilters = ({
  filters,
  config,
}: {
  filters?: Record<string, any>;
  config?: FiltersConfig['accreditations'];
}) => {
  if (!config || !filters) return [];

  return config
    .map((accr) => {
      const accreditationName = accr?.key;
      const accreditationStatus = accreditationName && filters[accreditationName];
      const accreditationStatusKey = accreditationStatus && accreditationsFiltersMap[accreditationStatus];

      if (!accreditationStatusKey) return null;

      return {
        LogicalOperator: LOGICAL_OPERATOR.AND,
        Filters: [
          {
            Property: 'ContactAccreditations.Name',
            Values: [accreditationName],
            Operator: EQUALITY_OPERATOR.EQUALS,
          },
          {
            Property: accreditationStatusKey,
            Values: [true],
            Operator: EQUALITY_OPERATOR.EQUALS,
          },
        ],
      };
    })
    .filter(Boolean);
};

export const makeQueryFilters = ({
  filters,
  options,
}: {
  filters?: Record<string, any>;
  options: { allowedRoleSubCategories: VenueRoleSubCategory[]; minCharsForSearch?: number };
}): ClassicQueryFilter[] => {
  const { search } = filters ?? {};
  const { allowedRoleSubCategories, minCharsForSearch = 0 } = options;
  return [
    {
      Property: 'Status',
      Values: [VenueContactStatus.ACTIVE],
      Operator: EQUALITY_OPERATOR.IN,
    },
    ...(allowedRoleSubCategories?.length
      ? [
          {
            Property: 'ContactRoles.VenueRole.SubCategory',
            Values: allowedRoleSubCategories,
            Operator: EQUALITY_OPERATOR.IN,
          },
        ]
      : []),
    ...(typeof search === 'string' && search.length >= minCharsForSearch
      ? [
          {
            Property: 'FirstName',
            Values: [search],
            Operator: EQUALITY_OPERATOR.CONTAINS,
          },
        ]
      : []),
  ];
};
