import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { classicRestClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { useColumnDefinitions } from 'src/components/admin-table/hooks';
import { withNotifications } from 'src/components/notification-container/notification-container';
import { defaultAccessoriesBarSpacing } from 'src/components/organisations/constants';
import { useViewingContext } from 'src/components/organisations/organisation-profile/hooks';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getRootProviderId } from 'src/config/config';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { GET_ORG_COACHES } from 'src/queries/REST/organisations';
import { VenueRoleSubCategory } from 'src/types/classic';
import { GetOrganisationCoaches } from 'src/types/classic-queries';
import { SORT_DIRECTION } from 'src/utils/constants/classic';
import { defaultPageTitleTransKeys } from 'src/utils/constants/labels';
import { mapToClassicSorts } from 'src/utils/helper/sortOptions';

import { createConfigs, defaultColumnsConfig, defaultQueryError, MIN_SEARCH_CHARS, tableId } from './config';
import { CoachesProps, CoachesTableItem } from './types';
import { makeAccreditationsFilters, makeQueryFilters } from './utils';

const useQFT = initQueryFilterTableHook<GetOrganisationCoaches, CoachesTableItem>(GET_ORG_COACHES);

const Coaches = ({
  allowedRoleSubCategories = [VenueRoleSubCategory.COACHING_TEAM],
  filtersConfig,
  columnsConfig = defaultColumnsConfig,
  transKeyTitle = defaultPageTitleTransKeys.COACHES,
  transKeyQueryError = defaultQueryError,
}: CoachesProps) => {
  const { t } = useTranslation();
  const tenantId = getRootProviderId();
  const selectedFacilityId = useOrgId();
  const { viewingContext } = useViewingContext() ?? {};
  const columns = useColumnDefinitions(columnsConfig);
  const { filters, urlParams } = useMemo(
    () => createConfigs({ t, viewingContext, filtersConfig, selectedFacilityId }),
    [t, viewingContext, filtersConfig, selectedFacilityId],
  );
  const {
    components: { FilterBar, AccessoryBar, AdminTable, props },
    query: { error },
  } = useQFT(
    filters,
    {
      columns,
      accessoryBar: {
        items: [
          { type: 'pagination-info-top', position: 'left', props: {} },
          {
            type: 'column-toggle',
            position: 'right',
            props: {},
          },
        ],
        gap: 12,
        spacing: defaultAccessoriesBarSpacing,
      },
      tableProps: {
        tableId,
        hideTopPaginationInfo: true,
        rowsPerPageOptions: [25, 50],
      },
    },
    {
      urlParams,
      columnSelectorId: tableId,
      mapFiltersToQueryOptions: (ctx) => ({
        client: classicRestClient,
        getTotalItems: (data) => data?.organisationCoaches?.TotalItems ?? 0,
        transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
          const { region } = ctx.filters as { search: string; region: string };
          const { limit, offset, sorts } = vars;
          const defaultSorting = [{ Property: 'FirstName', SortDirection: SORT_DIRECTION.ASCENDING }];

          const queryFilters = makeQueryFilters({
            filters: ctx.filters,
            options: { allowedRoleSubCategories, minCharsForSearch: MIN_SEARCH_CHARS },
          });

          const accreditationFilters = makeAccreditationsFilters({
            filters: ctx.filters,
            config: filtersConfig?.accreditations,
          });

          return {
            input: {
              ParentRegionId: tenantId,
              RegionId: region || undefined,
              QueryParametersPaged: {
                Limit: limit,
                Offset: offset,
                Sorts: sorts ? mapToClassicSorts(sorts) : defaultSorting,
                Filters: queryFilters,
                FilterGroups: accreditationFilters?.length ? accreditationFilters : undefined,
              },
            },
          };
        },
        tableId,
        defaultFetchLimit: 25,
      }),
      mapDataToTable: (data) => data?.organisationCoaches?.Items ?? [],
      depsFilterConfig: [filters],
      depsTableConfig: [columns],
    },
  );

  return (
    <PageMaxWidth>
      <Grid>
        <PageHeader title={t(transKeyTitle)} />
      </Grid>

      <Panel extendedPadding>
        <FilterBar {...props.filterBar} withClearBtn />
        <AccessoryBar {...props.accessoryBar} />
        <AdminTable {...props.adminTable} error={error ? t(transKeyQueryError) : undefined} />
      </Panel>
    </PageMaxWidth>
  );
};

export default withNotifications(Coaches);
